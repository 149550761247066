import { general } from "config/config"
import Head from "next/head"

import { ICrumb } from "./Breadcrumb"

export default function BreadcrumbHead({ crumbs }: { crumbs: ICrumb[] }) {
    return (
        <Head>
            <script
                dangerouslySetInnerHTML={{
                    __html: JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "BreadcrumbList",
                        itemListElement: crumbs.map((crumb, index) => ({
                            "@type": "ListItem",
                            item: `${general.appHost}${crumb.url}`,
                            name: crumb.name,
                            position: index + 1
                        }))
                    })
                }}
                type="application/ld+json"
            />
        </Head>
    )
}
