import Link from "components/Link"
import { rewrites } from "config/config"
import { t } from "locales"
import { useRouter } from "next/router"

import HomeIcon from "@mui/icons-material/Home"
import Breadcrumbs from "@mui/material/Breadcrumbs"
import Container from "@mui/material/Container"
import Paper from "@mui/material/Paper"
import Skeleton from "@mui/material/Skeleton"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"

import { IPage } from "data/contentData/api/content"
import { CONTENT_TYPE, getContentType } from "lib/get-content-type"

import BreadcrumbHead from "./BreadcrumbHead"

export interface ICrumb {
    name: string
    url: string
}

interface IProps {
    page?: IPage
}

function getCrumbs(page?: IPage) {
    const { parents, name } = page
        ? page
        : {
              parents: [],
              name: ""
          }

    // Remove non-lists & first list-element (/handboken)
    const crumbs: ICrumb[] =
        parents
            ?.slice()
            .reverse()
            .filter(p => getContentType(p.types) === CONTENT_TYPE.LIST)
            .slice(1) ?? []

    crumbs.push({
        name: name,
        url: ""
    })

    return crumbs
}

export default function Breadcrumb({ page }: IProps) {
    const router = useRouter()

    // Fetch translations for paths in route.rewrites
    const pathname = router.pathname in rewrites && router.pathname
    if (!page && pathname) {
        const name =
            t.breadcrumb.pathnames[
                pathname as keyof typeof t.breadcrumb.pathnames
            ]
        return (
            <StyledPaper>
                <Container fixed>
                    <BreadcrumbHead crumbs={[{ url: router.pathname, name }]} />
                    <Breadcrumbs
                        aria-label={t.breadcrumb.breadcrumbs}
                        separator="›">
                        <HomeLink
                            aria-label={t["go-to-homepage"]}
                            href="/"
                            prefetch={false}>
                            <HomeIcon color="action" />
                        </HomeLink>
                        <Typography variant="body2">{name}</Typography>
                    </Breadcrumbs>
                </Container>
            </StyledPaper>
        )
    }

    if (!page) {
        return (
            <StyledPaper>
                <Container fixed>
                    <Breadcrumbs
                        aria-label={t.breadcrumb.breadcrumbs}
                        separator="›">
                        <HomeLink
                            aria-label={t["go-to-homepage"]}
                            href="/">
                            <HomeIcon color="action" />
                        </HomeLink>
                        <Skeleton width={148} />
                        <Skeleton width={97} />
                        <Skeleton width={88} />
                        <Skeleton width={116} />
                    </Breadcrumbs>
                </Container>
            </StyledPaper>
        )
    }

    const crumbs = getCrumbs(page)

    return (
        <StyledPaper>
            <Container fixed>
                <BreadcrumbHead crumbs={crumbs} />
                <Breadcrumbs
                    aria-label={t.breadcrumb.breadcrumbs}
                    itemsAfterCollapse={4}
                    itemsBeforeCollapse={1}
                    maxItems={5}
                    separator="›">
                    <HomeLink
                        aria-label={t["go-to-homepage"]}
                        href="/">
                        <HomeIcon color="action" />
                    </HomeLink>

                    {crumbs.map(crumb => {
                        return !crumb.url ? (
                            <Typography
                                variant="body2"
                                component="span"
                                key={crumb.url}>
                                {crumb.name}
                            </Typography>
                        ) : (
                            <Link
                                variant="body2"
                                key={crumb.url}
                                href={crumb.url}>
                                {crumb.name}
                            </Link>
                        )
                    })}
                </Breadcrumbs>
            </Container>
        </StyledPaper>
    )
}

const StyledPaper = styled(Paper)(({ theme }) => ({
    display: "none",
    [theme.breakpoints.up("md")]: {
        position: "sticky",
        top: theme.constants.height.header,
        zIndex: 2,
        display: "block",
        padding: theme.spacing(1, 0),
        transition: ".2s"
    },
    [theme.breakpoints.up("lg")]: {
        padding: theme.spacing(1, 0)
    },
    "@media print": {
        display: "none !important"
    }
}))

const HomeLink = styled(Link)(() => ({
    display: "flex",
    "& > svg": {
        width: 16,
        height: 16
    }
}))
