import { Node } from "components/Drawer/NodeDrawer"
import Icon from "components/Icon"
import Link from "components/Link"
import { t } from "locales"
import * as React from "react"

import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"
import Button from "@mui/material/Button"
import ListItem from "@mui/material/ListItem"
import { styled } from "@mui/material/styles"

import NavigationSubsection from "./NavigationSubSection"

export default function NavigationSection(props: Node) {
    const { name, url, active, nodes, shortcut, icon, disabled } = props

    // Handle semi-controlled open state of subsections
    const [_open, setOpen] = React.useState<boolean | undefined>(undefined)

    const handleToggleExpanded = (e: any) => {
        e.stopPropagation()
        setOpen(prev => typeof prev === "undefined" || !prev)
    }

    React.useEffect(() => {
        setOpen(active)
    }, [active])

    const open = _open ?? active ?? false

    return (
        <StyledListItem disableGutters>
            <StyledLink
                expanded={Boolean(open || active).toString()}
                title={name}
                button
                disabled={disabled}
                fullWidth
                href={shortcut ?? url}
                startIcon={icon && <Icon name={icon} />}>
                {name}
            </StyledLink>
            {nodes ? (
                <>
                    <ExpandButton
                        sx={{
                            backgroundColor: open
                                ? "background.default"
                                : "transparent"
                        }}
                        aria-label={
                            open
                                ? t["open-section"]["true"]
                                : t["open-section"]["false"]
                        }
                        onClick={handleToggleExpanded}>
                        {open ? (
                            <ExpandLess color="action" />
                        ) : (
                            <ExpandMore color="action" />
                        )}
                    </ExpandButton>
                    <NavigationSubsection
                        nodes={nodes}
                        open={open}
                    />
                </>
            ) : null}
        </StyledListItem>
    )
}

const StyledListItem = styled(ListItem)(() => ({
    tion: "background-color .2s",
    padding: 0,
    position: "relative",
    zIndex: 1,
    display: "flex",
    flexWrap: "wrap"
}))

const StyledLink = styled(Link)<{ expanded: string }>(
    ({ expanded, theme }) => ({
        flex: 1,
        borderRadius: 0,
        justifyContent: "flex-start",
        minWidth: 48,
        height: 48,
        padding: theme.spacing(1, 1.25),
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",

        ...(expanded === "true" && {
            backgroundColor: theme.palette.background.default
        }),

        "&:focus": {
            boxShadow: `inset 0 0 0 3px ${theme.palette.secondary.main}`
        }
    })
)

const ExpandButton = styled(Button)(({ theme }) => ({
    borderRadius: 0,
    justifyContent: "flex-start",
    minWidth: 48,
    height: 48,
    padding: theme.spacing(1, 1.25),
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",

    "&:focus": {
        boxShadow: `inset 0 0 0 3px ${theme.palette.secondary.main}`
    }
}))
